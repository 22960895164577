import { createSlice } from '@reduxjs/toolkit';

export const actionSlice = createSlice({
	name: 'actions',
	initialState: {},
	reducers: {
		setEditing: (
			state,
			action: {
				payload: {
					personalInfo?: boolean;
					parentInfo?: boolean;
					guardianInfo?: boolean;
				};
			}
		) => {
			return {
				...state,
				editing: action.payload,
			};
		},
		setLoading: (state, action: any) => {
			return {
				...state,
				loading: action.payload,
			};
		},
	},
});

// this is for dispatch
export const { setEditing, setLoading } = actionSlice.actions;

// this is for configureStore
export default actionSlice.reducer;
