import translations from '../../lang/default.json';
import _ from 'lodash';

const __ = (key, replaceKeys = {}, lang = 'km') => {
	// const lang = 'km';
	const defaultLang = translations?.default;

	const translateValue = _.get(translations, key, {});

	if (translateValue.length <= 0) {
		return key;
	}

	return translateValue[lang] || translateValue[defaultLang] || key;
};

export { __ };
export default __;
