// assets
import { IconSchool, IconUserCircle, IconBuilding } from '@tabler/icons';
import __ from 'utils/translations';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const mainMenu = {
	id: 'main-menu',
	type: 'group',
	children: [
		{
			id: 'students',
			title: __('students'),
			type: 'item',
			url: '/students',
			icon: IconUserCircle,
			breadcrumbs: false,
		},
		{
			id: 'teachers',
			title: __('teachers'),
			type: 'item',
			url: '/teachers',
			icon: IconSchool,
			breadcrumbs: false,
		},
		// {
		// 	id: 'classroom',
		// 	title: __('classroom'),
		// 	type: 'item',
		// 	url: '/classroom',
		// 	icon: IconBuilding,
		// 	breadcrumbs: false,
		// },
	],
};

export default mainMenu;
