import { createSlice } from '@reduxjs/toolkit';

export const studentSlice = createSlice({
	name: 'student',
	initialState: null,
	reducers: {
		setStudent: (state: any, action: any) => {
			return action.payload;
		},
	},
});

// this is for dispatch
export const { setStudent } = studentSlice.actions;

// this is for configureStore
export default studentSlice.reducer;
