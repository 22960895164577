import { lazy } from 'react';

// project imports
import Loadable from '../components/Loadable';

// dashboard routing
const LoginPage = Loadable(lazy(() => import('../pages/login')));

// ==============================|| MAIN ROUTING ||============================== //

const AuthRoutes = {
	path: '/login',
	element: <LoginPage />
};

export default AuthRoutes;
