// material-ui
import logo from '../assets/images/logo.svg';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ width = 140, ...props }) => {
	return <img src={logo} {...props} alt='SMGT' width={width} />;
};

export default Logo;
