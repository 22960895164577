import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from '../../../utils/config';
import Logo from '../../../components/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
	<ButtonBase disableRipple component={Link} to={config.defaultPath}>
		<Logo />
	</ButtonBase>
);

export default LogoSection;
