import { createSlice } from '@reduxjs/toolkit';

export const schoolSlice = createSlice({
	name: 'school',
	initialState: null,
	reducers: {
		setSchool: (state: any, action: any) => {
			return action.payload;
		},
	},
});

// this is for dispatch
export const { setSchool } = schoolSlice.actions;

// this is for configureStore
export default schoolSlice.reducer;
