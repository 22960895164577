import studentReducer from './slices/studentSlice';
import schoolReducer from './slices/schoolSlice';
import studentListReducer from './slices/studentListSlice';
import actionReducer from './slices/actionSlice';
import classTypeReducer from './slices/classTypesSlice';
import { combineReducers } from '@reduxjs/toolkit';

// reducer import
import customizationReducer from './customizationReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
	customization: customizationReducer,
	students: studentListReducer,
	actions: actionReducer,
	student: studentReducer,
	school: schoolReducer,
	classTypes: classTypeReducer,
});

export default reducer;
