import { lazy } from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../components/Loadable';

// dashboard routing
const StudentsPage = Loadable(lazy(() => import('../pages/students')));
const TeachersPage = Loadable(lazy(() => import('../pages/teachers')));
const ClassroomPage = Loadable(lazy(() => import('../pages/classroom')));
const NewStudentPage = Loadable(
	lazy(() => import('../pages/students/NewPage'))
);
const StudentPay = Loadable(lazy(() => import('../pages/students/PayPage')));
const ViewDetailStudent = Loadable(
	lazy(() => import('../pages/students/ViewDetailPage'))
);
const ReceiptDetailPage = Loadable(
	lazy(() => import('../pages/students/ReceiptDetail'))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
	path: '',
	element: <MainLayout />,
	children: [
		{
			path: '/',
			element: <StudentsPage />,
		},
		{
			path: '/students',
			children: [
				{
					path: '/students',
					element: <StudentsPage />,
				},
				{
					path: '/students/new',
					element: <NewStudentPage />,
				},
				{
					path: '/students/:id',
					element: <ViewDetailStudent />,
				},
				{
					path: '/students/:id/pay',
					element: <StudentPay />,
				},
				{
					path: '/students/:id/invoice/:invoiceId',
					element: <ReceiptDetailPage />,
				},
			],
		},
		{
			path: '/teachers',
			element: <TeachersPage />,
		},
		{
			path: '/classroom',
			element: <ClassroomPage />,
		},
	],
};

export default MainRoutes;
