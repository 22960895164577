import { createSlice } from '@reduxjs/toolkit';

export const studentListSlice = createSlice({
	name: 'students',
	initialState: [],
	reducers: {
		addStudent: (state: any, action: any) => {
			const student = {
				...action.payload,
			};

			state.push(student);
		},
	},
});

// this is for dispatch
export const { addStudent } = studentListSlice.actions;

// this is for configureStore
export default studentListSlice.reducer;
