import { createSlice } from '@reduxjs/toolkit';

export const classTypesSlice = createSlice({
	name: 'classTypes',
	initialState: [],
	reducers: {
		setClassTypes: (state: any, action: any) => {
			return action.payload;
		},
	},
});

// this is for dispatch
export const { setClassTypes } = classTypesSlice.actions;

// this is for configureStore
export default classTypesSlice.reducer;
